<template>
  <div :class="chartType">
		<canvas class="h-full w-full"></canvas>
	</div>
</template>

<script>
/**
 * Chart
 *  Adapted from: https://github.com/Ajiva-D/vuecharts/tree/master/vue-chart/src
 */
import Chartjs from "chart.js";

export default {
  name: 'Chart',
  props: {
		chartType: String,
		chartData: Object,
		chartOptions: Object
	},
	methods: {
		/**
     * Initialize Chart
     * @param chartType string
     * @param chartData object
     * @param chartOptions object
     */
		init(chartType, chartData, chartOptions) {
			const chartElement = document.querySelector(`.${this.chartType} canvas`);
      // Merge client defined options with default options
      const allOptions = { ...this.defaultOptions, ...chartOptions } 
			const chartjs = new Chartjs(chartElement, {
				type: chartType,
				data: chartData,
				options: allOptions,
			});
      return chartjs;
		},
	},
	mounted() {
		let { chartType, chartData, chartOptions } = this;
		this.init(chartType, chartData, chartOptions);
	},
  data() {
		return {
      defaultOptions: {
        tooltips: {
          mode: "label",
          callbacks: {
            label: function(tooltipItem, data) {
              return ( data.datasets[tooltipItem.datasetIndex].label + ": " + tooltipItem.xLabel );
            },
          }
        },
        scales: {
          xAxes: [
            {
              fontSize: 5,
              stacked: true,
              gridLines: { 
                display: false 
              }              
            }
          ],
          yAxes: [
            {
              fontSize: 5,
              gridLines: {
								color:"#eee"
							},
              ticks: {
                stepSize: 1,
                callback: function(value, index, values) {
                  if (value % Math.round(values[0] / 6) == 0) {
                    return value;
                  } else if (value === 0) {
                    return value;
                  }
                }
              }
            }
          ]
        },
        maintainAspectRatio: false,
        legend: {
          labels: {
            boxWidth: 10
          },
          position: 'top'
        },
        animation: {
          duration: 2000,
          easing: 'easeInOutQuart'
        }
			},
		};
	}
}
</script>

<style scoped>
/* canvas{
	height: 500px;
} */
</style>