<template>
  <div class="chart">
		<BaseChart 
      :chartData="chartData" 
      :chartOptions="chartOptions" 
      :chartType="chartType" 
    />
	</div>
</template>

<script>
import BaseChart from './Chart.vue'

export default {
  name: 'BarChart',
  props: {
		chartData: Object
	},
  components: {
    BaseChart
  },
  data() {
		return {
			chartType: "bar",      
      chartOptions: {
        scales: {
          xAxes: [
            {
              categoryPercentage: 0.5,
              barPercentage: 0.5,
            }
          ],
          yAxes: [
            {
              stacked: true,
              categoryPercentage: 0,
              barPercentage: 0,
            }
          ]
        }
      },
		};
	},
}
</script>
    