<template>
  <div class="chart">
		<BaseChart 
      :chartData="chartData" 
      :chartOptions="chartOptions" 
      :chartType="chartType" 
    />
	</div>
</template>

<script>
import BaseChart from './Chart.vue'

export default {
  name: 'BarChart',
  props: {
		chartData: Object
	},
  components: {
    BaseChart
  },
  data() {
		return {
			chartType: "line",      
      chartOptions: { },
		};
	},
}
</script>
    